























































































.order__id {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
